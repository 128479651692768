<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    created() {
      if (!this.$store.getters.userId){
        this.$router.push("/signin");
      }
    },
  }
</script>

<style>
  @import '/assets/styles/style.css';
</style>