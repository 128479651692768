import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Lerning from './views/Learning'
import Learn from './views/Learn'
import Signin from './views/Signin'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin
    },
    {
      path: '/learning',
      name: 'learning',
      component: Lerning
    },
    {
      path: '/learning/:id',
      name: 'learn',
      component: Learn
    },
    {
      path: '*',
      redirect: '/'
    },
  ],
  mode: 'hash'
})
