<template>
    <div class="body">
        <div class="learning">
            <div class="learning-list">
                <div @click="goTo" v-for="course in courses" :data-to="'/learning/'+course.ID+'/'" class="learning-list-item" v-bind:key="course.ID">
                    {{course.NAME}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '../config';
    export default {
        name: "Learning",
        data() {
            return {
                url: config.backend_url,
                courses: []
            }
        },
        methods: {
            goTo(e){
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getCourses(){
                let self = this;
                this.axios.get(this.url+"BX24/getCourses/").then((response) => {
                    self.courses = response.data;
                })
            }
        },
        created() {
            this.getCourses()
            this.$router.push("/learning/3/")
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>