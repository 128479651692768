<template>
    <div class="body">
        <div class="learn-header">
            <div class="learn-header-top">
                <div class="learn-header-top-logo">
                    <img src="/assets/images/logo.png">
                </div>
                <div  class="learn-header-top-menu">
                    Онлайн семинар «Микробиота и методы ее алиментарной коррекции»
                </div>
            </div>
        </div>
        <div class="signin">
            <div class="signin-header">Введите E-mail</div>
            <div class="signin-input">
                <input type="text" v-model="mail" v-on:keyup.enter="signIn" placeholder="E-mail"></div>
            <div class="signin-button">
                <button @click="signIn">Войти</button>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '../config';

    export default {
        name: "Signin",
        data() {
            return {
                url: config.backend_url,
                mail: null
            }
        },
        methods: {
            signIn() {
                let self = this;
                this.axios.get(this.url + "BX24/signIn/?mail=" + this.mail).then((response) => {
                    if (response.data.result) {
                        self.$store.dispatch("SET_USERID", response.data.userID);
                        // self.$store.dispatch("SET_USERNAME", response.data.name);
                        self.$router.push("/learning/3/");
                    } else {
                        alert("Данного пользователя нет в системе");
                    }
                })
            }
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>