<template>
    <div class="body">
        <div class="learn-header">
            <div class="learn-header-top">
                <div class="learn-header-top-logo">
                    <img src="/assets/images/logo.png">
                </div>
                <div @click="toogleMenu" class="learn-header-top-menu hidden-desc">
                    Программа Семинара
                </div>
                <div class="learn-header-top-menu hidden-mob" v-html="courseName">
                </div>
            </div>
            <div v-if="showMenu" class="learn hidden-desc">
                <div class="learn-list">
                    <div @click="getVideo" :data-watch="lectFiles[k]" :data-lect="k" v-for="(name, k) in lectNames"
                         :class="'learn-list-item '+((currentVideo==k)? 'active ':'')+ ((watchedLects < k)? 'inactive':'')"
                         v-bind:key="k">
                        {{name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="learn-head hidden-desc" v-html="courseName">
        </div>
        <hexagon v-if="!showAll" style="position: absolute;top: 50vh;left: 50vw;"></hexagon>
        <div v-if="showAll" class="learn-desc-field">
            <div class="learn hidden-mob">
                <div class="learn-name">{{user.user.NAME}}</div>
                <div class="learn-list">
                    <div @click="getVideo" :data-watch="lectFiles[k]" :data-lect="k" v-for="(name, k) in lectNames"
                         :class="'learn-list-item '+((currentVideo==k)? 'active ':'')+ ((watchedLects < k)? 'inactive':'')"
                         v-bind:key="k">
                        {{name}}
                    </div>
                </div>
                <div class="video-quiz">
                    <div class="video-quiz-head">Запишите свои вопросы</div>
                    <div class="video-quiz-qst">
                        <textarea v-model="marks" @change="saveMarks"></textarea>
                    </div>
                </div>
            </div>
            <div class="learn-currentlect hidden-desc" v-html="lectNames[currentVideo]">

            </div>
            <div v-if="showVideo" class="video">
                <video-player class="video-player-box"
                              ref="videoPlayer"
                              :options="playerOptions"
                              :playsinline="true"
                              @ended="onPlayerEnded($event)"
                >
                </video-player>
                <div class="video-buttons">
                    <div class="video-buttons-prev" v-if="currentVideo > 0" @click="prev">Предыдущее</div>
                    <div class="video-buttons-next" v-if="currentVideo < 5 && showNext" @click="next">Следующее</div>
                    <div class="video-buttons-getcert video-buttons-next" v-if="showAnket && !issetCert" v-scroll-to="'#anketa'">Получить сертификат</div>
                    <div class="video-buttons-getcert video-buttons-next" v-if="showAnket && issetCert" v-scroll-to="'#bonuses'">Получить бонусы</div>
                </div>
            </div>
        </div>
        <div class="video-quiz hidden-desc">
            <div class="video-quiz-head">Запишите свои вопросы</div>
            <div class="video-quiz-qst">
                <textarea v-model="marks" @change="saveMarks"></textarea>
            </div>
        </div>
        <div v-if="watchedLects == 5 && !gotCert && showAnket" class="anketa" id="anketa">
            <div class="anketa-head">
                Поздравляем с успешным прохождением курса {{courseName}}.<br> Заполните анкету ниже для получения сертификата и бонусных программ.
            </div>
            <div class="anketa-buttons">
                <div class="anketa-buttons-buttons">
                    <button @click="setMark" data-mark="0" :class="(mark == 0)? 'activeBut':''">Отлично</button>
                    <button @click="setMark" data-mark="1"
                            :class="'anketa-buttons-buttons-middle '+((mark == 1)? 'activeBut':'')">Хорошо
                    </button>
                    <button @click="setMark" data-mark="2" :class="(mark == 2)? 'activeBut':''">Плохо</button>
                </div>
                <div v-if="mark" class="anketa-buttons-comment">
                    <textarea :placeholder="markDescr[mark]" v-model="comment"></textarea>
                </div>

            </div>
            <div class="anketa-name-desc">
                <div class="anketa-name">
                    <div class="anketa-name-head">
                        Ваши Фамилия и Имя для сертификата:
                    </div>
                    <div class="anketa-name-input">
                        <input type="text" placeholder="Фамилия Имя" v-model="nameCert">
                    </div>
                </div>
                <div class="anketa-spec">
                    <div class="anketa-spec-head">
                        Пожалуйста, укажите Вашу специализацию:
                    </div>
                    <div class="anketa-spec-input">
                        <input type="text" v-model="specialize" placeholder="Специализация">
                    </div>
                </div>
            </div>
            <div class="anketa-choose" >
                <div v-if="showManager && !issetCert && manager.PROPERTY_145" class="anketa-choose-head">
                    Ваш Персональный Менеджер
                </div>
                <div v-if="showManager && !issetCert && manager.PROPERTY_145" class="anketa-choose-items">
                    <div class="anketa-choose-items-item">
                        <div class="anketa-choose-items-item-left">
                            <img :src="JSON.parse(manager.photo).src"/>
                        </div>
                        <div class="anketa-choose-items-item-right">
                            <div class="anketa-choose-items-item-right-head">
                                {{manager.NAME}}
                            </div>
                            <div class="anketa-choose-items-item-right-descr" v-html="manager.PROPERTY_145[0].TEXT">
                            </div>
                            <!--                            <button>Изменить менеджера</button>-->
                        </div>
                    </div>
                </div>

                <div class="anketa-getcert">
                    <button @click="getCert">{{buttons.getCert}}</button>
                </div>
                <div v-if="showManager && !issetCert" class="anketa-getcert">
                    <button @click="sendManagerShow" v-scroll-to="'#message'">НАПИСАТЬ СВОЕМУ МЕНЕДЖЕРУ</button>
                </div>
                <div v-if="showMessage" class="anketa-message" id="message">
                    <div class="anketa-message-text">
                        <textarea v-model="messageToManager.message" placeholder="Введите сообщение сюда"></textarea>
                    </div>
                    <button @click="sendManager">ОТПРАВИТЬ</button>
                </div>
            </div>
        </div>
        <div v-if="showPresent && !cert.url" class="cert-loading" id="bonuses">
            <span><span v-if="!issetCert">Подождите, идет выпуск сертификата.</span><br>БОНУСЫ УЧАСТНИКАМ СЕМИНАРА</span>
            <mike-pad v-if="!issetCert" style="margin: auto;"></mike-pad>
        </div>
        <div v-if="cert.url" class="cert-download">
            <div class="cert-download-img"><img src="/assets/images/sertifikat_SPZ-04_1x.png"/></div>
            <a :href="cert.url" target="_blank" class="cert-download-button">Скачать сертификат</a>
        </div>
        <div v-if="showPresent" class="anketa-bonuses">
            <div v-for="(src, i) in presents.src" v-bind:key="i" class="anketa-bonuses-item"
                 :style="'background-color: '+presents.color[i]">
                <div class="anketa-bonuses-item-img">
                    <img :src="presents.img[i]">
                </div>
                <div class="anketa-bonuses-item-text">
                    <div class="anketa-bonuses-item-text-header" v-html="presents.head[i].TEXT">
                    </div>
                    <div class="anketa-bonuses-item-text-text" v-html="presents.text[i].TEXT">

                    </div>
                </div>
                <a :href="src" target="_blank" class="anketa-bonuses-item-button">Скачать</a>
            </div>
        </div>
        <div class="footer">
            <div class="footer-phone">
                <a href="tel:88005112503">8 800 511 25 03</a>
            </div>
            <div class="footer-mail">
                <a href="mailto:info@spz03.ru">info@spz03.ru</a>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '../config';
    import {Hexagon, MikePad} from 'vue-loading-spinner'

    export default {
        components: {
            Hexagon,
            MikePad
        },
        name: "Learn",
        data() {
            return {
                messageToManager: {
                    message: null
                },
                hooperSettings: {
                    itemsToShow: 1,
                    infiniteScroll: true,
                    centerMode: false
                },
                buttons: {
                    getCert: "СКАЧАТЬ СЕРТИФИКАТ"
                },
                cert: {
                    url: null,
                    doc: null
                },
                comment: null,
                specialize: null,
                nameCert: null,
                showMenu: false,
                courseName: null,
                user: [],
                url: config.backend_url,
                course: this.$route.params.id,
                lectNames: [],
                lectFiles: [],
                presents: [],
                presentsSrc: [],
                watchedLects: 0,
                currentVideo: 0,
                showVideo: false,
                showNext: false,
                marks: null,
                mark: null,
                markDescr: ["Пожалуйста, напишите, что Вам понравилось", "Пожалуйста, напишите, что Вам понравилось", "Пожалуйста, напишите, что Вам не понравилось и что можно улучшить"],
                playerOptions: {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [{
                        type: "video/mp4",
                        src: ""
                    }],
                    poster: "",
                },
                issetCert: false,
                showAnket: false,
                showPresent: false,
                showAll: false,
                showMessage: false,
                showManager: true,
                gotCert: false,
                manager: []
            }
        },
        methods: {
            sendManagerShow(){
                this.showMessage = (this.showMessage)? false:true;
            },
            sendManager(){
                let self = this;
                if (this.messageToManager.message){
                    this.axios.post('http://service.vitamax.ru/api/', {
                        query: `query send($to: String, $from:String , $message:String, $title: String) {
                                          send(to: $to, from: $from, message: $message, title: $title) {
                                            result
                                            text
                                          }
                                        }`,
                        variables: {
                            to: /*"i@nikitailin.com"*/self.manager.PROPERTY_151,
                            from: "info@spz03.ru",
                            message: "У вас новый специалист хочет подписаться "+self.user.user.EMAIL[0].VALUE+". Его зовут: "+self.user.user.NAME+" "+self.user.user.LAST_NAME+". Его телефон: "+self.user.user.PHONE[0].VALUE+". Вот его сообщение:"+self.messageToManager.message,
                            title: "Вопрос специалиста с Семинара Микробиоты"
                        }
                    });
                    alert("Сообщение отправлено");
                    self.sendManagerShow();
                }
                else {
                    alert("Введите сообщение для менеджера")
                }
            },
            setMark(e) {
                let mark = e.srcElement.dataset.mark;
                this.mark = mark;
            },
            toogleMenu() {
                this.showMenu = (this.showMenu) ? false : true;
            },
            onPlayerEnded() {
                this.showNext = true;
                if (this.watchedLects == 5) {
                    this.showAnket = true;
                    if (this.issetCert){
                        this.showPresent = true;
                        this.showManager = false;
                    }
                }
            },
            getVideo(e) {
                let self = this;
                let videoID = e.srcElement.dataset.watch;
                let lect = e.srcElement.dataset.lect;
                if (lect <= this.watchedLects) {
                    this.axios.get(this.url + "BX24/getVideo/?file=" + videoID).then((response) => {
                        self.$set(self.playerOptions.sources, 0, {
                            type: "video/mp4",
                            src: response.data.src,
                        });
                        self.currentVideo = lect;
                        self.showMenu = false;
                        setTimeout(() => self.showVideo = true, 300);
                    })
                }
            },
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getCourse() {
                let self = this;
                this.axios.get(this.url + "BX24/getCourse/?id=" + this.course).then((response) => {
                    self.lectNames = response.data.PROPERTY_115;
                    self.lectFiles = response.data.PROPERTY_117[0];
                    self.presents = {
                        src: response.data.PROPERTY_143,
                        head: response.data.PROPERTY_155,
                        text: response.data.PROPERTY_159,
                        img: response.data.PROPERTY_161,
                        color: response.data.PROPERTY_163
                    }
                    self.courseName = response.data.NAME;

                })
            },
            getCert() {
                if (this.nameCert && this.specialize) {
                    if (this.buttons.getCert != "СЕРТИФИКАТ ВЫПУСКАЕТСЯ") {
                        let self = this;
                        this.buttons.getCert = "СЕРТИФИКАТ ВЫПУСКАЕТСЯ";
                        let mark = "Отлично";
                        switch (this.mark) {
                            case 1:
                                mark = "Хорошо";
                                break;
                            case 2:
                                mark = "Плохо";
                                break;
                        }
                        // let phone = (self.user.user.PHONE[0].VALUE)? self.user.user.PHONE[0].VALUE : "";
                        if (self.manager.NAME) {
                            this.axios.post('http://service.vitamax.ru/api/', {
                                query: `query send($to: String, $from:String , $message:String, $title: String) {
                                          send(to: $to, from: $from, message: $message, title: $title) {
                                            result
                                            text
                                          }
                                        }`,
                                variables: {
                                    to: /*"i@nikitailin.com"*/self.manager.PROPERTY_151,
                                    from: "info@spz03.ru",
                                    message: "Добрый день, " + self.manager.NAME + "!\n" +
                                        "\n" +
                                        " \n" +
                                        "\n" +
                                        "Новый Специалист завершил изучение курса «Микробиота и методы ее алиментарной коррекции».\n" +
                                        "\n" +
                                        "" + self.user.user.NAME + " " + self.user.user.LAST_NAME + "\n" +
                                        "" + self.user.user.EMAIL[0].VALUE + "\n" +
                                        // ""+phone+"\n" +
                                        "" + self.specialize + "\n" +
                                        "" + mark + "\n" +
                                        "Вопрос:" + self.messageToManager.message + "\n" +
                                        "Заметки: " + self.comment,
                                    title: "Новый Специалист прошел курс «Микробиота и методы ее коррекции»"
                                }
                            });
                        }
                        this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.user.ID + "&prop_SECOND_NAME=&prop_LAST_NAME=" + "&prop_NAME=" + this.nameCert + "&prop_UF_CRM_1592296871857=" + this.specialize + "&prop_UF_CRM_1592471707799=" + mark + "&prop_UF_CRM_1592471696040=" + this.comment + "&prop_UF_CRM_1592296935752="+this.manager.PROPERTY_149[0]+"&prop_UF_CRM_1597132271065=Да").then(() => {
                            self.showAnket = false;
                            self.showPresent = true;
                        });
                        this.axios.get(this.url + "BX24/getCert/?userid=" + this.user.user.ID).then((response) => {
                            self.cert.url = response.data.url;
                            self.cert.doc = response.data.doc[0];
                            if (self.manager.NAME) {
                                self.axios.post('http://service.vitamax.ru/api/', {
                                    query: `query send($to: String, $from:String , $message:String, $title: String) {
                                          send(to: $to, from: $from, message: $message, title: $title) {
                                            result
                                            text
                                          }
                                        }`,
                                    variables: {
                                        to: /*"i@nikitailin.com"*/self.user.EMAIL[0].VALUE,
                                        from: "info@spz03.ru",
                                        message: "Уважаемый (ая), " + self.user.user.NAME + " " + self.user.user.LAST_NAME + "!" +
                                            "\n" +
                                            "Поздравляем Вас с успешным прохождением курса «Микробиота и методы ее алиментарной коррекции».\n" +
                                            "\n" +
                                            " \n" +
                                            "\n" +
                                            "Получите свой Сертификат по ссылке: " + "<a href='" + self.cert.url + "'>Скачать</a>" + "\n" +
                                            "\n" +
                                            " \n" +
                                            "\n" +
                                            "Бонусные программы для участников:" +
                                            "<a href='" + self.presents[0].src + "'>" + self.presents[0].head + "</a>" + "\n" +
                                            "<a href='" + self.presents[1].src + "'>" + self.presents[1].head + "</a>" + "\n" +
                                            "<a href='" + self.presents[2].src + "'>" + self.presents[2].head + "</a>" + "\n" +
                                            "<a href='" + self.presents[3].src + "'>" + self.presents[3].head + "</a>" + "\n" +
                                            "\n" +
                                            " \n" +
                                            "\n" +
                                            "По вопросам участия в следующих курсах и медицинских конференциях Вы можете обратиться к прикрепленному Персональному менеджеру",
                                        title: "Сертификат участника «Микробиота и методы ее алиментарной коррекции»"
                                    }
                                });
                            }
                        });
                    }
                } else {
                    alert("Заполните Свое ФИО для Сертификата и Специализацию")
                }
            },
            saveMarks() {
                this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.user.ID + "&prop_UF_CRM_1592294982084=" + this.marks);
            },
            next() {
                this.showVideo = false;
                this.showNext = false;
                this.currentVideo++;
                this.watchedLects++;
                this.watchedLects++;
                let start = 0;
                let finish = 0;
                if (this.watchedLects == 2){
                    start = 1;
                }
                if (this.watchedLects == 6){
                    finish = 1;
                }
                this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.user.ID + "&prop_UF_CRM_1592294959951=" + this.watchedLects+"&start="+start+"&finish="+finish);
                this.getVideoById(this.currentVideo);
                this.watchedLects--;
            },
            prev() {
                this.showVideo = false;
                this.currentVideo--;
                this.getVideoById(this.currentVideo);
            },
            getVideoById(id) {
                let self = this;
                let videoID = this.lectFiles[id];
                this.axios.get(this.url + "BX24/getVideo/?file=" + videoID).then((response) => {
                    self.$set(self.playerOptions.sources, 0, {
                        type: "video/mp4",
                        src: response.data.src,
                    });
                    setTimeout(() => self.showVideo = true, 1000);
                    setTimeout(() => self.showAll = true, 1000);
                })
            },
            getUserById(id) {
                let self = this;
                this.axios.get(this.url + "BX24/getUserInfo/?id=" + id).then((response) => {
                    self.user = response.data;
                    self.watchedLects = (self.user.user.UF_CRM_1592294959951 == "") ? 1 : self.user.user.UF_CRM_1592294959951;
                    self.marks = self.user.user.UF_CRM_1592294982084;
                    self.watchedLects--;
                    self.currentVideo = self.watchedLects;
                    if (self.user.user.UF_CRM_1592296935752 != "") {
                        self.issetCert = true;
                    }
                    if (self.user.user.UF_CRM_1597132271065 != "") {
                        self.gotCert = true;
                    }

                })
            },
            getManager(){
                let self = this;
                let idManager = (this.user.user.UTM_SOURCE) ? this.user.user.UTM_SOURCE : "";
                this.axios.get(this.url + "BX24/getSpec/?id=" + idManager).then((response) => {
                    self.manager = response.data;
                });
            }
        },
        created() {
            this.getCourse();
            this.getUserById(this.$store.getters.userId);
            setTimeout(() => this.getManager(), 1000);
        },
        mounted() {
            setTimeout(() => this.getVideoById(this.currentVideo), 5000);

        }
    }
</script>

<style scoped>

</style>