import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VeeValidate from 'vee-validate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import VueScrollTo from 'vue-scrollto'
import VueCoreVideoPlayer from 'vue-core-video-player'


Vue.use(VueCoreVideoPlayer)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
Vue.use(vueAwesomeCountdown, 'vac')

Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.config.productionTip = false
Vue.use(VueCookies)



new Vue({
  router,
  store,
  render: h => h(App),
  /*mounted() {
    document.dispatchEvent(new Event('render-event'))
  }*/
}).$mount('#app')
