import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: cookie.get("userId"),
    userName: null
  },
  mutations: {
    SET_USERID: (state, payload) => {
      state.userId = payload;
    },
    SET_USERNAME: (state, payload) => {
      state.userName = payload;
    },
  },
  actions: {
    SET_USERID (context, payload) {
      cookie.set("userId", payload, 36000000000);
      context.commit('SET_USERID', payload);
    },
    SET_USERNAME (context, payload) {
      context.commit('SET_USERNAME', payload);
    },
  },
  getters: {
    userId: state => {
      return state.userId
    },
    userName: state => {
      return state.userName
    }
  }
})
