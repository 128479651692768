<template>
    <div class="body">
        <div class="header">

        </div>
        <div class="main-menu">
            <div @click="goTo" data-to="learning" class="main-menu-link">
                Обучение
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {

            }
        },
        methods: {
            goTo(e){
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            }
        },
        created() {
            this.$router.push("/learning/3/")
        },
        mounted() {
        }
    }
</script>